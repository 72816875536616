import React from 'react';
import SectionLayout from '../../../layouts/SectionLayout';
import frameworkFlow from '../../../images/cai_inventory_transparent.png';
import SEO from '../../../components/SEO';
import caiFlow from '../../../images/cai_flow_framework.png';
import mjdFlow from '../../../images/cai_sensor_flow.png';

export default function SoftwareInventory() {
  return (
    <>
      <SEO />
      <SectionLayout marginBottom={false} showBottomBorder={false}>
        <div className="mobile-desktop-text-margin">
          <p className="section-display-subheader">YOUR RECORDS. ONE PLACE.</p>
          <p>
            {`Your Ag Operation's inventory has incredible value that is always
            changing. Whether you want to track every tree in a field or map
            every underground tile line, our software allows you to inventory
            those assets with user friendly GIS tools and monitor those assets
            as the value changes over time.`}
          </p>
        </div>
        <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
          <img
            className="img-fluid mt-3"
            alt="Framework overview flow diagram"
            src={frameworkFlow}
          />
        </div>
      </SectionLayout>
      <SectionLayout showBottomBorder={false} leftClasses="flex-grow-1">
        <h4 className="section-display-subheader">Integrated Data</h4>
        <p style={{ maxWidth: '900px', margin: '0 auto' }}>
          Our platform automatically aggregates your field, seed, and yield data
          into one secure place—your data silo. The data assigns a GPS location
          to the correct field boundary, providing accurate mapping and
          reporting.
        </p>
        <div style={{ maxWidth: '700px', margin: '0 auto' }}>
          <img className="img-fluid" alt="CAI flow diagram" src={caiFlow} />
        </div>
      </SectionLayout>
      <SectionLayout>
        <h4 className="section-display-subheader">Machine IoT Sensors</h4>
        <p style={{ maxWidth: '900px', margin: '0 auto' }}>
          {`Our software collects and cleans volumes of machine sensor data so you
          don't have to. Summaries are provided for the entire area under
          management all the way down to the specific crop, product, or plant.`}
        </p>
        <div style={{ maxWidth: '700px', margin: '0 auto' }}>
          <img className="img-fluid" alt="iot flow diagram" src={mjdFlow} />
        </div>
      </SectionLayout>
    </>
  );
}
